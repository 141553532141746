<template>
    <AppPaginatedGridNested
        ref="grid"
        :service="service"
        :parentName="parentName"
        :queryParams="queryParams"
        :defaultProps="queryParams"
        :expander="false"
        :labelBotaoAdicionarItem="labelBotaoAdicionarItem"
        :isPaginated="false"
        :showButtonAlterar="true"
    >
        <template #header>
            <h4>{{ 'Descrição do risco' }}</h4>
        </template>

        <template #columns>
            <Column field="riscoDescricaoRisco" header="Descrição risco">
                <template #editor="{ data, field }">
                    <DropdownRiscoDescricaoRisco
                        v-model="data[field]"
                        @update:modelValue="data.riscoDescricaoRiscoId = data[field]?.id"
                        :filtrosExtras="{ riscoId: queryParams.riscoId }"
                    />
                </template>
                <template #body="slotProps">
                    {{ slotProps?.data?.riscoDescricaoRisco?.descricaoRisco?.nome }}
                </template>
            </Column>
        </template>
    </AppPaginatedGridNested>
</template>

<script>
import CrudService from '@/services/crud';
import DropdownRiscoDescricaoRisco from '../../../../descricao-risco/components/DropdownRiscoDescricaoRisco.vue';

export default {
    components: {
        DropdownRiscoDescricaoRisco
    },
    props: {
        queryParams: {
            type: Object
        },
        service: {
            type: Object,
            required: true
        },
        parentName: {
            type: String
        },
        labelBotaoAdicionarItem: {
            type: String
        }
    },
    mounted() {
        this.$service = new CrudService('/descricao-risco');
    },
    data() {
        return {
            descricaoRiscos: []
        };
    },
    methods: {
        onAfterSave() {
            this.$refs.grid.load();
        }
    }
};
</script>

<style></style>
