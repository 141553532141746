<template>
    <AppPaginatedGridNested
        ref="grid"
        :service="service"
        :parentName="parentName"
        :queryParams="queryParams"
        :defaultProps="queryParams"
        :expander="false"
        :labelBotaoAdicionarItem="labelBotaoAdicionarItem"
        :isPaginated="false"
        :showButtonAlterar="true"
        @onAfterSave="onAfterSave"
    >
        <template #header>
            <h4>Medidas de controle existentes</h4>
        </template>

        <template #columns>
            <Column field="tipo" header="Tipo">
                <template #editor="{ data, field }">
                    <Dropdown
                        v-model="data[field]"
                        :options="medidasControleTipo"
                        optionLabel="label"
                        optionValue="value"
                        @change="setaTipoMedicao(data[field])"
                    />
                </template>
                <template #body="slotProps">
                    {{ getLabelMedidaControleTipo(slotProps?.data?.tipo).label }}
                </template>
            </Column>

            <Column field="epi" header="EPI">
                <template #editor="{ data, field }">
                    <DropdownEpi v-if="data['tipo'] == 'EPI'" v-model="data[field]" @update:modelValue="data.epiId = data[field]?.id" />
                    <Divider v-else />
                </template>
                <template #body="slotProps">
                    {{ slotProps?.data?.epi?.nome }}
                </template>
            </Column>

            <Column field="epc" header="EPC">
                <template #editor="{ data, field }">
                    <DropdownEpc v-if="data['tipo'] == 'EPC'" v-model="data[field]" @update:modelValue="data.epcId = data[field]?.id" />
                    <Divider v-else />
                </template>
                <template #body="slotProps">
                    {{ slotProps?.data?.epc?.nome }}
                </template>
            </Column>

            <Column field="descricao" header="Descrição" headerStyle="width:20%; min-width:10rem;">
                <template #editor="{ data, field }">
                    <div class="p-inputgroup">
                        <DescricaoMedidas v-model="data[field]" :tipoMedidaControle="tipoMedidaControle" />
                    </div>
                </template>
                <template #body="slotProps">
                    {{ slotProps?.data?.descricao }}
                </template>
            </Column>

            <Column header="Anexo">
                <template #editor>
                    <Divider />
                </template>
                <template #body="slotProps">
                    <Button label="Anexar / Visualizar" class="bg-primary-reverse" @click="incluirAnexo(slotProps.data)" />
                </template>
            </Column>

            <Column field="certificadoAprovacao" header="CA">
                <template #editor="{ data, field }">
                    <InputNumber v-if="data['tipo'] == 'EPI'" v-model="data[field]" />
                    <Divider v-else />
                </template>
                <template #body="slotProps">
                    {{ slotProps?.data?.certificadoAprovacao }}
                </template>
            </Column>

            <Column field="dataInclusao" header="Data Inclusão">
                <template #editor="{ data, field }">
                    <Calendar v-model="data[field]" />
                </template>
                <template #body="slotProps">
                    {{ formatData(slotProps?.data?.dataInclusao) }}
                </template>
            </Column>
        </template>
    </AppPaginatedGridNested>
</template>

<script>
import CrudService from '@/services/crud';
import moment from 'moment-timezone';
import DropdownEpc from '../../../../EPC/components/DropdownEpc.vue';
import DropdownEpi from '../../../../EPI/components/DropdownEpi.vue';
import DescricaoMedidas from './DescricaoMedidas.vue';
import MedidaControleEnum from '../../../../../enums/MedidaControleEnum';
import OrigemAnexoEnum from '../../../../../enums/OrigemAnexoEnum';
import TipoAnexoEnum from '../../../../../enums/TipoAnexoEnum';

export default {
    components: {
        DropdownEpc,
        DropdownEpi,
        DescricaoMedidas
    },
    props: {
        queryParams: {
            type: Object
        },
        service: {
            type: Object,
            required: true
        },
        parentName: {
            type: String
        },
        labelBotaoAdicionarItem: {
            type: String
        }
    },
    mounted() {
        this.$epiService = new CrudService('/epi');
        this.$epcService = new CrudService('/epc');
        this.load();
    },
    data() {
        return {
            form: {
                descricao: null
            },
            isOpen: false,
            tipoMedidaControle: null,
            medidasControleTipo: MedidaControleEnum.MedidaControle
        };
    },
    methods: {
        async load() {
            this.epis = (await this.$epiService.findAll({})).data.items;
            this.epcs = (await this.$epcService.findAll({})).data.items;
        },
        formatData(data) {
            if (data) return moment.tz(data, 'YYYY-MM-DD', 'America/Sao_Paulo').format('DD/MM/YYYY');
            return '';
        },
        incluirAnexo(data) {
            this.$emit(
                'onIncluirAnexo',
                data.id,
                OrigemAnexoEnum.CHECKLIST_PERIGO_MEDIDA_CONTROLE_EXISTENTE,
                TipoAnexoEnum.CHECKLIST_PERIGO_MEDIDA_CONTROLE_EXISTENTE
            );
        },
        getLabelMedidaControleTipo(value) {
            const medidaControleTipo = this.medidasControleTipo.find((p) => p.value === value);
            return medidaControleTipo || { label: 'Não informado' };
        },
        onCloseDialog() {
            this.isOpen = false;
        },
        setaTipoMedicao(value) {
            if (
                value == this.medidasControleTipo.find((p) => p.value == 'ENGENHARIA').value ||
                value == this.medidasControleTipo.find((p) => p.value == 'ADMINISTRATIVA').value
            ) {
                this.tipoMedidaControle = value;
            } else {
                this.tipoMedidaControle = null;
            }
        },
        async abrirDialogGridMedidaControle() {
            setTimeout(() => this.$refs.dialogGridMedidaControle.open(this.tipoMedidaControle), 0);
        },
        sendDescricaoCopy(props) {
            this.teste = props;
        },
        onAfterSave() {
            this.tipoMedidaControle = null;
        }
    }
};
</script>
